import styled from 'styled-components';
import {
  border,
  BorderProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  width,
  WidthProps,
} from 'styled-system';

const Image = styled.img<SpaceProps & BorderProps & LayoutProps & WidthProps>`
  ${space}
  ${border}
  ${layout}
  ${width}
`;

Image.defaultProps = {
  display: 'block',
};

export default Image;
